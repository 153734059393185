<template>
  <div class="" style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='col-md-8 d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.push({path: '/purchasing'})}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>{{singular}} #{{purchaseOrder.id}}</h1>
          <div v-if="getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_PO')" style="width: 100%;">
            <dynamicButtonContainer :containerId="`${getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_PO')}`" :data="{status: purchaseOrder.status, id1: $route.params.id}" style="width: 100%;"/>
          </div>
        </v-col>
        <v-col class="col-md-4">
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-column">
          <span v-if="purchaseOrder.createdAt">Created By {{lookupUsername(purchaseOrder.createdBy)}} at {{utils.formatDate(purchaseOrder.createdAt, 'withTime')}}</span>
          <span v-if="purchaseOrder.sealedAt">Sealed By {{lookupUsername(purchaseOrder.sealedBy)}} at {{utils.formatDate(purchaseOrder.sealedAt, 'withTime')}}</span>
          <span v-if="purchaseOrder.voidedAt">Voided By {{lookupUsername(purchaseOrder.voidedBy)}} at {{utils.formatDate(purchaseOrder.voidedAt, 'withTime')}}</span>
        </v-col>
      </v-row>
      <v-row v-if="!loader">
        <v-col cols="8" style="background-color: rgba(0,0,0,0.05); border-radius: 10px; padding: 20px;">
          <div class="d-flex flex-column">
            <div style="width: 100%;">
              <!-- header -->
              <div style="width: 100%; margin-top: 10px;" class="d-flex flex-row justify-space-between">
                <div class="d-flex flex-column" style="width:50%;">
                  <span>
                    <h2>Supplier</h2>
                  </span>
                  <div class="d-flex flex-column">
                    <h3>{{purchaseOrder.supplierInfo.name}}</h3>
                    <h4>{{purchaseOrder.supplierInfo.contactName}}</h4>
                    <h4>{{purchaseOrder.supplierInfo.address}}</h4>
                    <h4>{{purchaseOrder.supplierInfo.phone}}</h4>
                    <h4>{{purchaseOrder.supplierInfo.email}}</h4>
                  </div>
                </div>
                <div class="d-flex flex-column" style="width:50%;">
                  <span>
                    <h2>Ship To</h2>
                  </span>
                  <div class="d-flex flex-column">
                    <h3>{{purchaseOrder.shipToInfo.name}}</h3>
                    <h4>{{purchaseOrder.shipToInfo.address}}</h4>
                    <h4>{{purchaseOrder.shipToInfo.phone}}</h4>
                    <h4>{{purchaseOrder.shipToInfo.email}}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div style="border: 2px solid rgba(0,0,0,0.15); border-radius: 10px; margin-top: 20px;">
              <!-- body -->
                <v-simple-table style="height: 100%; background-color: rgba(0,0,0,0)">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-center">
                      QTY
                    </th>
                    <th class="text-center">
                      ID
                    </th>
                    <th class="text-center">
                      Name
                    </th>
                    <th class="text-center">
                      SKU
                    </th>
                    <th class="text-center">
                      Unit Price
                    </th>
                    <th class="text-right">
                      Total
                    </th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in purchaseOrder.PurchaseOrderLineItems" :key="item.id">
                      <td class="text-center">{{utils.pff(item.quantity)}}</td>
                      <td class="text-center">
                        <router-link :to="'/products/view/'+item.productId">{{item.productId}}</router-link>
                      </td>
                      <td class="text-center">
                        <span>{{item.productName}}</span>
                        <span v-if="item.metadata.customName && item.productName!==item.metadata.customName"><br><b>SIN: </b>{{item.metadata.customName}}</span>
                      </td>
                      <td class="text-center">{{item.sku}}</td>
                      <td class="text-center">{{utils.formatCurrency(item.unitPrice)}}</td>
                      <td class="text-right">{{utils.formatCurrency(item.metadata.lineTotal||0)}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div class="d-flex flex-row justify-end" style="width: 100%; margin-top: 10px;">
              <div style="width: 120px; padding-right:20px;" class="d-flex flex-column align-end">
                <span class="d-flex flex-column align-end">
                  <span>
                    <span class="text-h5">Subtotal</span>
                    <span class="text-h4 ml-5">{{utils.formatCurrency(purchaseOrder.subtotal)}}</span>
                  </span>
                  <span>
                    <span class="text-h5">Total</span>
                    <span class="text-h4 ml-5">{{utils.formatCurrency(purchaseOrder.grandTotal)}}</span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="4" v-if="getGlobalValue('VEC_ADMIN_INCLUDE_RR_IN_PO')==='true'" >
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <h1>{{this.secondaryPlural}}</h1>
              <v-btn
                  v-if="purchaseOrder.status===1"
                  class="mx-2"
                  fab
                  small
                  dark
                  color="info"
                  @click="createA"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                  :headers="headers"
                  :items="receivingReports"
                  :items-per-page="-1"
                  class="elevation-1"
                  @click:row="rowClick"
              >
                <template v-slot:item.sealedAt="{ item }">
                  <span>{{utils.formatDate(item.sealedAt)}}</span>
                </template>
                <template v-slot:item.sealedBy="{ item }">
                  <span>{{lookupUsername(item.sealedBy)}}</span>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip :color="utils.getRRStatusColor(item.status)">{{utils.parseRRStatus(item.status)}}</v-chip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
      </v-row>
    </v-container>
    <v-dialog v-model="createDialog.isOpen" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Create New Receiving Report
        </v-card-title>
        <v-card-actions>
          <v-btn color="error" text @click="cancelCreate">
            Cancel
          </v-btn>
          <v-btn color="success" @click="createB">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  import utils from "../../plugins/helpers"
  import dynamicButtonContainer from './../../components/dynamicButtonContainer.vue';
  export default {
    components: {
      dynamicButtonContainer
    },
    data () {
      return {
        utils: utils,

        loader: false,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        data: {},
        userTypes: [],
        products: [],

        singular: "Purchase Order",
        singularLower: "purchaseorder",
        plural: "Purchase Orders",
        pluralLower: "purchaseorders",

        secondarySingular: "Receiving Report",
        secondarySingularLower: "receivingreport",
        secondaryPlural: "Receiving Reports",
        secondaryPluralLower: "receivingreports",

        suppliers: [],
        supplierProducts: [],
        purchaseOrder: {
          sealedDocument: {},
          PurchaseOrderLineItems: [],
          subtotal: 0, 
          grandTotal: 0, 
          supplierInfo: {}, 
          shipToInfo: {
            companyName: "Teelucksingh's Furniture Limited",
            address: "57 High Street, Princes Town",
            phone: '3332123',
            email: 'teelucksinghs@gmail.com',
        }},

        receivingReports: [],

        headers: [
          { text: 'ID', align: 'start', sortable: true, value: 'id' },
          { text: '# of Items', align: 'start', sortable: true, value: 'itemCount' },
          { text: 'Date Sealed', align: 'start', sortable: true, value: 'sealedAt' },
          { text: 'Sealed By', align: 'start', sortable: true, value: 'sealedBy' },
          { text: 'Status', align: 'start', sortable: true, value: 'status' },
        ],

        valid: false,
        taxRate: 10,
        created: false,
        isNewPO: "",
        supplierEditing: false,
        shipToEditing: false,
        tempSupplierInfo: {},
        tempShipToInfo: {},
        renderKey: 0,
        deleteDialog: false,
        createDialog: {
          isOpen: false,
          confirmed: false
        }
      }
    },
    async mounted(){
      try{
        this.loader = true;

        let po = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/withReports/${this.$route.params.id}`);
        if(po.data.error) throw po.data.error
        this.purchaseOrder = po.data.data
        this.purchaseOrder.PurchaseOrderLineItems.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0))

        this.receivingReports = this.purchaseOrder.ReceivingReports;

        this.updateTotals()
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.loader = false;
      }
    },
    computed: {
      ...mapGetters(['getEndpoint', "getId", 'lookupUsername', 'getGlobalValue'])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      updateTotals(){
        this.purchaseOrder.PurchaseOrderLineItems.forEach(item=>item.metadata.lineTotal = utils.pff(item.quantity)*item.unitPrice)
        this.purchaseOrder.subtotal = this.purchaseOrder.PurchaseOrderLineItems.reduce((a,x)=>a+x.metadata.lineTotal,0)
        // this.purchaseOrder.grandTotal = ((this.taxRate/100)*this.purchaseOrder.subtotal) + this.purchaseOrder.subtotal
        this.purchaseOrder.grandTotal = this.purchaseOrder.subtotal
        this.$forceUpdate()
      },
      rowClick(row){
        this.$router.push({ path: `/receiving/view/${row.id}`})
      },
      createA(){
        this.createDialog.isOpen = true
      },
      cancelCreate(){
        this.createDialog.isOpen = false;
      },
      async createB(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/${this.secondaryPluralLower}`, {poId: this.purchaseOrder.id, supplierId: this.purchaseOrder.supplierId})
          if(res.data.error) throw res.data.error
          this.createDialog.isOpen = false
          await this.$router.push({ path: `/receiving/view/${res.data.data.id}`})
        } catch (error) {
         console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
         this.createDialog.isOpen = false
        }
      }
    }
  }
</script>
